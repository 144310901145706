import React from 'react'
import './Proyects.css'
import ProyectsCard from './ProyectsCard/ProyectsCard'
import {PROYECTS} from '../../utils/data'
import Slider from 'react-slick'
import { useRef } from 'react'

const Proyects = () => {
    const sliderRef = useRef();
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        ],
    };

    const slideRight = () => {
        sliderRef.current.slickNext();
    };
    const slideLeft = () => {
        sliderRef.current.slickPrev();
    }
  return (
    <section className='experience-container'>
        <h5>Proyects</h5>
        <div className='experience-content'>
        <div className='arrow-right' onClick={slideRight}>
            <span className='material-icons'>chevron_right</span>
        </div>
        <div className='arrow-left'>
            <span className='material-icons' onClick={slideLeft}>chevron_left</span>
        </div>

       
            <Slider ref={sliderRef} {...settings}>
            {PROYECTS.map((item) => (
                <ProyectsCard key={item.title} details={item} />
            ))}
            </Slider>
        </div>
    </section>
  )
}

export default Proyects