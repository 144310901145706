import React from 'react'
import './Hero.css'
import { FaReact, FaHtml5, FaCss3Alt   } from "react-icons/fa";
import { IoLogoJavascript } from "react-icons/io5";


const Hero = () => {
  return (
    <section className='hero-container'>
        <div className='hero-content'>
            <h2> Building Digital Experiences</h2>
            <p>
                My name is Matias Rivera Zahn, Passionate Junior Frontend Developer | Transforming Ideas into 
                Visually Stunning Web Solutions.
            </p>
        </div>
        <div className='hero-img'>
            <img className='img' src="/assets/imagenprog.jpg" alt='Imagen Programador'/>
            <div className='imgicons'>
            
                <div className='tech-icon'>
                <FaReact style={{ color: '#61DAFB', fontSize: '3.5rem' }} />
                </div>
                
            
                <div className='tech-icon'>
                <FaHtml5 style={{ color: '#E34C26', fontSize: '3.5rem' }} />
                </div>
            
                <div className='tech-icon'>
                <FaCss3Alt style={{ color: '#1572B6', fontSize: '3.5rem' }} />
                </div>
            
                <div className='tech-icon'>
                <IoLogoJavascript style={{ color: '#F7DF1E', fontSize: '3.5rem' }} />
                </div>
            </div>

        </div>
    </section>
  )
}

export default Hero