import './App.css';
import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import Skills from './components/Skills/Skills';
import Proyects from './components/Proyects/Proyects';
import ContactMe from './components/ContactMe/ContactMe';
import Footer from './components/Footer/Footer';


function App() {
  return (
      <>
      <Navbar />
      <div className='container'>
       <section id="home"> <Hero /> </section>
       <section id="skills"> <Skills /> </section>
        <section id="proyectos"><Proyects /></section>
        <section id="contact"><ContactMe /></section>
      </div>
      <Footer />
      </>
  );
}

export default App;
