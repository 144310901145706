import React, {useState} from 'react'
import './Navbar.css'
import { IoCodeSlashSharp, IoClose } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
import MobileNav from './MobileNav/MobileNav';
import { Link } from 'react-scroll';
const Navbar = () => {

    const [openMenu, setOpenMenu] = useState(false);
    const toggleMenu = () => {
        setOpenMenu(!openMenu);
    };

  return (
    <>
    <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />
    <nav className="nav-wrapper">
    <div className='nav-content'>
    <IoCodeSlashSharp className='logo'  />

    <ul>
        <li>
            <Link to="home" smooth={true} duration={500} className='menu-item'>Home</Link>
        </li>
        <li>
            <Link to="skills" smooth={true} duration={500} className='menu-item'>Skills</Link>
        </li>
        <li>
            <Link to="proyectos" smooth={true} duration={500} className='menu-item'>Proyects</Link>
        </li>
        <li>
            <Link to="contact" smooth={true} duration={500} className='menu-item'>Contact</Link>
        </li>
    </ul>

    <button className="menu-btn" onClick={toggleMenu}>
        <span>
        
        {openMenu ? <IoClose/> : <FaBars />}
        </span>
   
    </button>
    </div>

    </nav>
    
    </>
  )
}

export default Navbar