import React from 'react'
import './MobileNav.css'
const MobileNav = ({isOpen, toggleMenu}) => {
  return (
    <>
    <div className={`mobile-menu ${isOpen ? "active": ""}`} onClick={toggleMenu}>
    
    

        <div className='mobile-menu-container'>

        <ul>
        <li>
            <a href="#home" className='menu-item'>Home</a>
        </li>
        <li>
            <a href="#skills" className='menu-item'>Skills</a>
        </li>
        <li>
            <a href="#proyectos" className='menu-item'>Proyects</a>
        </li>
        <li>
            <a href="#contact" className='menu-item'>Contact</a>
        </li>
        </ul>
        </div>
     </div>
    </>
  )
}

export default MobileNav