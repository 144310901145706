import React from 'react'
import './ContactInfoCard.css'
import { MdEmail } from "react-icons/md";
import { FaGithub, FaLinkedin  } from "react-icons/fa";
const ContactInfoCard = ({iconType, text}) => {
  return (
    <div className='contact-details-card'>
        <div className='icon'>
            {iconType === 'email' && <MdEmail size={30} />}
            {iconType === 'github' && <FaGithub size={30} />}
            {iconType === 'linkedin' && <FaLinkedin size={30} />}
        </div>
        {iconType === 'email' ? (
            <p><a href={`mailto:${text}`}>{text}</a></p>
        ) : (
            <p><a href={text} target="_blank" rel="noopener noreferrer">{text}</a></p>
        )}
    </div>
  )
}

export default ContactInfoCard